import wx from 'weixin-js-sdk'
import { apiWeChatShare } from './api'
export const shareList = (
  title = '大盘手网-期货实盘争霸赛',
  desc = '第二届实盘大赛火热进行中，豪礼翻倍，等你来战！',
  link = 'https://m.dpswang.com/',
  imgUrl = 'https://m.dpswang.com/uploads/images/202106/weixin_icon.jpg'
) => {
  const userAgent = navigator.userAgent
  const ua = navigator.userAgent.toLowerCase()
  if (ua.includes('micromessenger')) {
    let shareUrl
    if (userAgent.includes('iPhone') || userAgent.includes('iPad')) {
      shareUrl = sessionStorage.getItem('originUrl')
    } else {
      shareUrl = location.href
    }
    apiWeChatShare({ url: shareUrl }).then(response => {
      if (response.status === 200) {
        const config = response.data
        wx.config(config)
        const param = {
          title, // 分享标题
          desc, // 分享描述
          link, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
          imgUrl, // 分享图标
          success: function () {
          }
        }

        // 需在用户可能点击分享按钮前就先调用
        wx.ready(function () {
          wx.checkJsApi({
            jsApiList: ['updateAppMessageShareData', 'updateTimelineShareData', 'onMenuShareTimeline', 'onMenuShareAppMessage'],
            success: function (res) {
            }
          })
          wx.updateAppMessageShareData(param)
          wx.updateTimelineShareData(param)
        })
      }
    })
  }
}
