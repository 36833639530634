import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import 'amfe-flexible/index.js'
// import 'default-passive-events'
import './assets/css/animation.css'
import './assets/css/animate.min.css'
import {
  NavBar,
  Tabs,
  Tab,
  Swipe,
  SwipeItem,
  Lazyload,
  Image,
  Loading,
  Row,
  Col,
  Button,
  Card,
  Search,
  Pagination,
  Field,
  CellGroup,
  Cell,
  ActionSheet,
  Dialog,
  PasswordInput,
  NumberKeyboard,
  Toast,
  DropdownMenu,
  DropdownItem,
  List,
  RadioGroup,
  Radio,
  Overlay,
  Form,
  Popup,
  Tabbar,
  TabbarItem,
  Sticky,
  ShareSheet,
  Grid,
  GridItem, CheckboxGroup, Checkbox
} from 'vant'
import animated from 'animate.css'
import wow from 'wowjs'

import moment from 'moment'
import { shareList } from './utils/wxShare'

Vue.prototype.$wow = wow
Vue.prototype.$moment = moment
Vue.prototype.$shareList = shareList
Vue.use(animated)
Vue.use(NavBar)
Vue.use(Tabs)
Vue.use(Tab)
Vue.use(Swipe)
Vue.use(SwipeItem)
Vue.use(Lazyload)
Vue.use(Image)
Vue.use(Loading)
Vue.use(Row)
Vue.use(Col)
Vue.use(Button)
Vue.use(Card)
Vue.use(Search)
Vue.use(Pagination)
Vue.use(Field)
Vue.use(CellGroup)
Vue.use(Cell)
Vue.use(ActionSheet)
Vue.use(Dialog)
Vue.use(PasswordInput)
Vue.use(NumberKeyboard)
Vue.use(Toast)
Vue.use(DropdownMenu)
Vue.use(DropdownItem)
Vue.use(List)
Vue.use(RadioGroup)
Vue.use(Radio)
Vue.use(Overlay)
Vue.use(Form)
Vue.use(Popup)
Vue.use(Tabbar)
Vue.use(TabbarItem)
Vue.use(Sticky)
Vue.use(ShareSheet)
Vue.use(Grid)
Vue.use(GridItem)
Vue.use(Checkbox)
Vue.use(CheckboxGroup)

Vue.config.productionTip = false

Vue.prototype.goBack = function () {
  if (window.history.length > 1) {
    this.$router.back()
  } else {
    this.$router.replace('/')
  }
}

router.beforeEach((to, from, next) => {
  if (to.meta.title) {
    document.title = to.meta.title
    const title = to.meta.shareTitle || '大盘手网-期货实盘争霸赛'
    const shareImg = to.meta.shareImg || 'https://m.dpswang.com/uploads/images/202106/weixin_icon.jpg'
    const desc = to.meta.desc || '第五届实盘大赛火热进行中，豪礼翻倍，等你来战！'
    const link = 'https://m.dpswang.com' + to.fullPath
    shareList(
      title,
      desc,
      link,
      shareImg
    )
  }
  if (to.meta.needLogin) {
    if (!store.getters.isLoggedIn) {
      if (to.name === 'Account' || to.name === 'S1Account' || to.name === 'SharesAccount') { // 2022.10.12去掉PerformanceAccount的登录校验
        Toast.fail('请登录后查看')
      }
      next({ path: '/login', query: { from: to.fullPath } })
    } else {
      next()
    }
  }
  next()
})

const userAgent = navigator.userAgent
if (userAgent.includes('iPhone') || userAgent.includes('iPad')) {
  sessionStorage.setItem('originUrl', location.href)
}
Vue.filter('numberSimplify', function (val) {
  if (!isNaN(val)) {
    if (Math.abs(val) / 100000000 > 1) {
      return (val / 100000000).toFixed(2) + '亿'
    } else if (Math.abs(val) / 10000 > 1) {
      return (val / 10000).toFixed(2) + '万'
    } else {
      return (val / 1).toFixed(2)
    }
  } else {
    return val
  }
})

Vue.filter('numberSimplifyNew', function (amount) {
  if (!amount) {
    return '0.00'
  }
  if (amount === '0') {
    return '0.00'
  }

  // //强制保留两位小数
  const sign = (amount === (amount = Math.abs(amount)))
  let f = parseFloat(amount)
  if (isNaN(f)) return false
  f = Math.round(amount * 100) / 100
  let s = f.toString()
  let rs = s.indexOf('.')
  if (rs < 0) {
    rs = s.length
    s += '.'
  }
  while (s.length < (rs + 1) + 2) {
    s += '0'
  }
  let leftNum = s.split('.')[0]
  const rightNum = '.' + s.split('.')[1]
  let result
  // 定义数组记录截取后的价格
  const resultArray = []
  if (leftNum.length > 3) {
    let i = true
    while (i) {
      resultArray.push(leftNum.slice(-3))
      leftNum = leftNum.slice(0, leftNum.length - 3)
      if (leftNum.length < 4) {
        i = false
      }
    }
    // 由于从后向前截取，所以从最后一个开始遍历并存到一个新的数组，顺序调换
    const sortArray = []
    for (let i = resultArray.length - 1; i >= 0; i--) {
      sortArray.push(resultArray[i])
    }
    result = leftNum + ',' + sortArray.join(',') + rightNum
  } else {
    result = s
  }
  return sign ? result : `-${result}`
})

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
