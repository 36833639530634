<template>
  <div id="app">
    <main class="main">
      <keep-alive>
        <router-view v-if="$route.meta.keepAlive"></router-view>
      </keep-alive>
      <router-view v-if="!$route.meta.keepAlive"></router-view>
    </main>

    <!-- <div class="footer" v-show="showFooter">
      <van-row class="links">
        <van-col span="12">
          <router-link to="/about" class="link">关于我们</router-link>
        </van-col>
        <van-col span="12">
          <router-link to="/help" class="link">帮助中心</router-link>
        </van-col>
      </van-row>
      <p class="copyright">版权所有 @重庆大盘手网络科技有限公司 渝ICP备19015980号-5</p>
    </div>addSharesAccount -->
    <footer-nav v-show="!$route.meta.hideFooter" activeName="index"></footer-nav>
    <van-loading class="loading" type="spinner" v-show="$store.state.isLoading"/>
    <level-up-tip></level-up-tip>
    <award-modal></award-modal>
    <notify-add-sc title="继续完成股票大赛参赛步骤" />
    <challenge-tip></challenge-tip>
  </div>
</template>
<script>
import LevelUpTip from './components/LevelUpTip'
import AwardModal from './components/AwardModal'
import FooterNav from './components/FooterNav'
import NotifyAddSc from './components/NotifyAddSc.vue'
import ChallengeTip from './components/ChallengeTip'
export default {
  name: 'App',
  components: {
    LevelUpTip,
    AwardModal,
    FooterNav,
    NotifyAddSc,
    ChallengeTip
  },
  data () {
    return {
      showFooter: true
    }
  }
}
</script>
<style lang="scss">
// body *{
//   -webkit-filter: grayscale(100%); /* webkit */
//   -moz-filter: grayscale(100%); /*firefox*/
//   -ms-filter: grayscale(100%); /*ie9*/
//   -o-filter: grayscale(100%); /*opera*/
//   filter: grayscale(100%);
//   filter:progid:DXImageTransform.Microsoft.BasicImage(grayscale=1);
//   filter:gray; /*ie9- */
// }
html, body {
  height: 100%;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  min-height: 100%;
  background: #F8F6F9;
  display: flex;
  flex-direction: column;
  position: relative;

  .loading {
    position: absolute;
    top: 50%;
    margin-top: -20px;
    left: 50%;
    margin-left: -20px;
  }
}

.main {
  flex: 1;
  display: flex;
  flex-direction: column;
  background-color: #f7f7f7;
  min-height: calc(100vh - 1.33333rem);
  // padding-bottom: 80px;
}

.wrap {
  padding: 10px 15px;
  font-size: 0;
}

.footer {
  font-size: 0;
  background: #AA0000;
  padding: 15px 0;
  margin-top: auto;

  .links {
    .link {
      font-size: 16px;
      color: #ffffff;
    }

    margin-bottom: 15px;
  }

  .copyright {
    color: #ffffff;
    font-size: 12px;
    margin: 0;
  }
}

.pagination {
  margin: 10px 0;

  .van-pagination__item {
    color: #AA0000;
  }

  .van-pagination__item--active {
    background-color: #AA0000;
    color: #ffffff;
  }
}
.header.dps-header {
  background: #AA0000;
  // height: auto;
  padding: 15px 0;
  // ::v-deep .router-link-exact-active {
  //   display: flex;
  //   margin: 0;
  //   padding: 0;
  //   justify-content: center;
  // }
  .logo{img{
    width: 101px;
  }}
}
</style>
