<template>
  <div class="add-account-notify" v-show="show">
    <div class="add-account-notify-bg"></div>
    <div class="add-account-main">
      <div class="title">{{ title }}</div>
      <div class="result-con">
        <h5>您的股票大赛报名未完成，请继续以下操作。</h5>
        <div class="email-con">{{email}}</div>
        <div>
          <p class="text-aa0000 mt mb">请您联系开户的证券公司客户经理，将您的结算交割单每日自动发送到您的专用邮箱{{email}}，以便进行排名统计！大部分证券公司都支持该功能，如有客户经理不愿意给您设置，欢迎转户到我们指定券商！</p>
        </div>
      </div>
      <div class="wrap">
        <div type="danger" class="add-account-btn setting-btn" @click="openCourse"><span>设置结算单</span></div>
        <p class="phone-title">参赛请同步添加客服微信号</p>
        <p class="wx">客服微信：<a href="https://work.weixin.qq.com/kfid/kfcf992d9b8b9d42f42">点击咨询客服</a></p>
        <p class="phone">客服电话：<a href="tel:023-63206910">023-63206910</a></p>
      </div>
      <div class="close-btn" @click="closeModal"></div>
    </div>
  </div>
</template>

<script>
import { apiScComplate } from '../utils/api'

export default {
  name: 'notifyAddSc',
  props: {
    title: String
  },
  components: { },
  data () {
    return {
      show: false,
      status: false,
      email: '',
      wap_course_url: ''
    }
  },
  created () {
    this.$store.state.userId && apiScComplate().then((response) => {
      if (response.data.success) {
        this.status = response.data.data.status
        this.email = response.data.data.email
        this.wap_course_url = response.data.data.company.wap_course_url
        this.show = !response.data.data.status
      }
    })
  },
  methods: {
    onClickLeft () {
      this.goBack()
    },
    closeModal () {
      this.show = false
    },
    // 查看教程
    openCourse () {
      const a = encodeURIComponent(`&email=${this.email}`)
      if (this.wap_course_url.includes('http')) {
        window.open(`${this.wap_course_url}${a}`, '_blank')
      } else {
        this.$router.push({ path: this.wap_course_url })
      }
      // window.location.href = this.wap_course_url
    }
  }
}
</script>

<style lang="scss" scoped>
  .add-account-notify {
    .add-account-notify-bg {
      flex: 1;
      display: flex;
      flex-direction: column;
      background-color: rgba(0,0,0,.3);
      position: fixed;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      z-index: 99;
    }
    .close-btn{
      position: absolute;
      width: 40px;
      display: inline-block;
      height: 40px;
      background: url('../assets/close-icon-white.png') no-repeat;
      background-size: cover;
      // margin: 20px auto 0;
      bottom: -60px;
      left: 50%;
      margin-left: -20px;
    }
    .add-account-main {
      width: 90%;
      // height: 500px;
      background: #ffffff;
      padding: 0 15px;
      box-sizing: border-box;
      top: 15%;
      left: 5%;
      border-radius: 20px;
      position: absolute;
      z-index: 100;
      .title {
        font-size: 18px;
        font-family: PingFang SC;
        font-weight: bold;
        line-height: 20px;
        color: #333333;
        padding: 15px 0;
      }
      .result-con {
        .text-aa0000 {
          color: #aa0000;
          font-weight: bold;
        }
        h5 {
          font-size: 14px;
          font-family: PingFang SC;
          font-weight: normal;
          color: #333333;
          text-align: center;
          margin: 0 0 20px;
        }
        .email-con {
          font-size: 18px;
          color: #aa0000;
          text-align: center;
          margin: 0 auto 20px;
          min-width: 225px;
          padding: 0 10px;
          height: 40px;
          background: #F5F5F5;
          line-height: 40px;
          font-weight: 500;
        }
        p {
          margin: 0;
          font-size: 14px;
          font-family: PingFang SC;
          line-height: 24px;
          text-align: center;
        }
        .mt {
          margin-top: 20px;
        }
        .mb {
          margin-bottom: 20px;
        }
      }

      .wrap {
        padding: 0;

        .add-account-btn {
          width: 100%;
          background: linear-gradient(0deg, #AA0000, #D41616);
          box-shadow: 0 2px 5px 0 rgba(7, 10, 32, 0.28);
          border-radius: 5px;
          height: 42px;
          line-height: 42px;
          font-size: 16px;
          color: #fff;
          &.setting-btn {
            cursor: pointer;

            span {
              padding-left: 20px;
              background: url('../assets/icon/jc-icon.png') no-repeat;
              background-size: 15px;
            }
          }
        }

        .phone-title {
          font-size: 13px;
          color: #999999;
          margin: 18px 0 0 0;
        }

        .phone, .wx {
          font-size: 13px;
          color: #999999;
          margin: 9px 0 18px 0;

          a {
            text-decoration: underline;
            color: #aa0000;
          }
        }
        .wx {
          margin-bottom: 5px;
        }
      }
    }
  }
</style>

<style lang="scss" scoped>
  .add-account {
    .add-account-header {
      height: 140px;
      background: #AA0000;
      position: relative;

      .header {
        background: #AA0000;
        color: #FFFFFF;
        height: auto;

        .van-nav-bar__left {
          height: 60px;
          line-height: 60px;

          i {
            color: #FFFFFF;
            font-size: 24px;
          }
        }

        .van-nav-bar__title {
          color: #ffffff;
          font-size: 18px;
          height: 60px;
          line-height: 60px;
        }

        .van-nav-bar__right {
          line-height: 60px;
          height: 60px;

          span {
            font-size: 14px;
            color: #ffffff;
            text-decoration: underline;
          }
        }
      }

      h2 {
        text-align: left;
        font-size: 18px;
        font-weight: bold;
        color: #ffffff;
        margin-left: 38.5px;
      }
    }

    .input {
      &.code-image-box {
        .van-field__button {
          height: 26px;

          .code-image {
            height: 25px;
          }
        }
      }
    }
  }

  .van-collapse-item__wrapper {
    position: absolute;
    z-index: 2;
    right: -22px;
    margin: 0 auto;
  }

  .van-collapse-item__title {
    padding: 10px 0;

    .company-title {
      text-align: start;
      color: #cccccc;

      &.active {
        color: #323233;
      }
    }
  }

  .van-collapse {
    position: relative;
    height: 59px;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;

    &.active {
      height: 84px;
    }
    .van-collapse-item {
      width: 100%;

      &.active {
        height: 70px;
      }

      &::after {
        left: 0;
        right: 0;
        border-bottom: 1px solid #BFBFBF;
        position: absolute;
        box-sizing: border-box;
        content: ' ';
        pointer-events: none;
        bottom: 0;
        -webkit-transform: scaleY(.5);
        -ms-transform: scaleY(.5);
        transform: scaleY(.5);
      }
    }
  }
</style>
