import axios from './http'
// 首届接口开始
// 综合排行榜
export const apiHistoryRankIndex = params => {
  // eslint-disable-next-line eqeqeq
  if (params?.rank == 2) return axios.get('/dpswapi/v1/rank-s2/index', { params: params })
  return axios.get('/dpswapi/v1/rank-s1/index', { params: params })
}

// 分组排行榜
export const apiHistoryRankGroup = params => {
  // eslint-disable-next-line eqeqeq
  if (params?.rank == 2) return axios.get('/dpswapi/v1/rank-s2/index', { params: params })
  return axios.get('/dpswapi/v1/rank-s1/group', { params: params })
}

// 账户信息
export const apiHistoryInfoAccount = params => {
  return axios.get('/dpswapi/v1/info-s1/account', { params: params })
}

// 账户信息
export const apiHistoryInfoAccountS2 = params => {
  return axios.get('/dpswapi/v1/info-s2/account', { params: params })
}

// 账户数据
export const apiHistoryInfoData = params => {
  return axios.get('/dpswapi/v1/info-s1/data', { params: params })
}

// 账户数据
export const apiHistoryInfoDataS2 = params => {
  return axios.get('/dpswapi/v1/info-s2/data', { params: params })
}

// 单项榜
export const apiHistoryRankSingle = params => {
  // eslint-disable-next-line eqeqeq
  if (params?.rank == 2) return axios.get('/dpswapi/v1/rank-s2/single', { params: params })
  return axios.get('/dpswapi/v1/rank-s1/single', { params: params })
}
// 首届接口结束
// 手机号密码登录接口---
export const apiPassLogin = params => {
  return axios.post('/passportapi/auth/login', params)
}

// 手机号动态码登录接口---
export const apiCodeLogin = params => {
  return axios.post('/passportapi/auth/login-with-sms', params)
}

// 微信登录---
export const apiWechatLogin = params => {
  return axios.post('/passportapi/auth/login-with-wechat', params)
}

// 绑定用户---
export const apiBindUser = params => {
  return axios.post('/passportapi/auth/bound', params)
}

// 发送手机验证码---
export const apiMCaptcha = params => {
  return axios.post('/passportapi/auth/send-sms', params)
}

// 退出接口---
export const apiLogout = params => {
  return axios.post('/dpswapi/v1/user/logout', params)
}

// 注册接口
export const apiSignup = params => {
  return axios.post('/dpswapi/v1/default/signup', params)
}

// 重置密码接口---
export const apiResetPassword = params => {
  return axios.post('/passportapi/auth/reset-password', params)
}

// 首页信息---
export const apiIndexData = params => {
  return axios.get('/dpswapi/v1/home/index', { params: params })
}
// 首页banner信息---
export const apiBannerData = params => {
  return axios.get('/dpswapi/v1/home/banner', { params: params })
}

// 战报详情文章详情---
export const apiReportDetail = params => {
  return axios.get('/dpswapi/v1/article/detail', { params: params })
}

// 实战秘籍详情
export const apiSecretDetail = params => {
  return axios.get('/dpswapi/v1/secret/view', { params: params })
}

// 高手访谈详情
export const apiMasterDetail = params => {
  return axios.get('/dpswapi/v1/master/view', { params: params })
}

// 新闻中心列表接口---
export const apiReportList = params => {
  return axios.get('/dpswapi/v1/article/index', { params: params })
}

// 新闻中心分类接口----
export const apiReportCategory = params => {
  return axios.get('/dpswapi/v1/article/category', { params: params })
}

// 视频中心列表接口---
export const apiVideosList = params => {
  return axios.get('/dpswapi/v1/video/index', { params: params })
}

// 视频中心列表分类接口----
export const apiVideosCategory = params => {
  return axios.get('/dpswapi/v1/video/category', { params: params })
}

// 实战秘籍列表接口
export const apiSecretList = params => {
  return axios.get('/dpswapi/v1/secret/lists', { params: params })
}

// 用户信息接口---
export const apiUserIndex = params => {
  return axios.get('/dpswapi/v1/user/info', { params: params })
}

// 用户签到接口---
export const apiUserSignin = params => {
  return axios.get('/dpswapi/v1/user/sign', { params: params })
}

// 用户期货账户信息接口---
export const apiAccountIndex = params => {
  return axios.get('/dpswapi/v1/fc-account/index', { params: params })
}

// 添加账户---
export const apiAccountCreate = params => {
  return axios.post('/dpswapi/v1/fc-account/create', params)
}

// 期货账户暂停参赛
export const apiFcAccStop = (params) => {
  return axios.post('/dpswapi/v1/fc-account/visible', params)
}

// 可退赛期货账户
export const apiAccountQuit = params => {
  return axios.get('/dpswapi/v1/fc-account/quit-accounts', { params: params })
}

// 期货退赛
export const apiFcAccQuit = (params) => {
  return axios.post('/dpswapi/v1/fc-account/quit', params)
}

// 获取单个账户信息---
export const apiAccountInfo = params => {
  return axios.get('/dpswapi/v1/fc-account/detail', { params: params })
}

// 更新账户---
export const apiAccountUpdate = params => {
  return axios.post('/dpswapi/v1/fc-account/update', params)
}

// 获取邀请数
export const apiInvitation = params => {
  return axios.post('/dpswapi/v1/invitation/index', params)
}
// 微信分享
export const apiWeChatShare = params => {
  return axios.post('/dpswapi/v1/share/config', params)
}

// 获取邀请列表--
export const apiInvite = params => {
  return axios.post('/dpswapi/v1/fc-invite/code', params)
}

// 获取邀请列表---
export const apiInviteList = params => {
  return axios.get('/dpswapi/v1/fc-invite/index', { params: params })
}

// 修改资料---
export const apiUpdateUserField = params => {
  return axios.post('/dpswapi/v1/user/edit-info', params)
}

// 修改密码---
export const apiUpdateUserPass = params => {
  return axios.post('/dpswapi/v1/user/edit-password', params)
}

// 修改手机号---
export const apiUpdateUserMobile = params => {
  return axios.post('/dpswapi/v1/user/edit-mobile', params)
}

// 赛季接口--
export const apiSeason = params => {
  return axios.get('/dpswapi/v1/fc-contest/season', { params: params })
}

// 轻量组接口--
export const apiRankLight = params => {
  return axios.get('/dpswapi/v1/fc-rank/light', { params: params })
}

// 重量组接口--
export const apiRankWeight = params => {
  return axios.get('/dpswapi/v1/fc-rank/weight', { params: params })
}

// 综合得分榜接口
export const apiRankIndex = params => {
  return axios.get('/dpswapi/v1/rank/index', { params: params })
}

// 月榜接口---
export const apiRankMonth = params => {
  return axios.get('/dpswapi/v1/fc-rank/month', { params: params })
}

// 季榜接口---
export const apiRankQuarter = params => {
  return axios.get('/dpswapi/v1/fc-rank/quarter', { params: params })
}

// 分组榜
export const apiRankGroup = params => {
  return axios.get('/dpswapi/v1/rank/group', { params: params })
}

// 单项榜---
export const apiRankSingle = params => {
  return axios.get('/dpswapi/v1/fc-rank/single', { params: params })
}

// 收益榜---
export const apiRankBenefit = params => {
  return axios.get('/dpswapi/v1/fc-rank/benefit', { params: params })
}

// 高频---
export const apiRankFrequency = params => {
  return axios.get('/dpswapi/v1/fc-rank/frequency', { params: params })
}

// 连盈榜---
export const apiRankFeature = params => {
  return axios.get('/dpswapi/v1/fc-rank/feature', { params: params })
}

// 个性榜
export const apiRankProgress = params => {
  return axios.get('/dpswapi/v1/rank/progress', { params: params })
}

// 个性榜
export const apiRankPopularity = params => {
  return axios.get('/dpswapi/v1/rank/popularity', { params: params })
}

// 用户信息接口---delete
export const apiUserInfo = params => {
  return axios.get('/dpswapi/v1/info/user', { params: params })
}

// 用户所有账户信息接口---delete
export const apiUserAccounts = params => {
  return axios.get('/dpswapi/v1/info/user-accounts', { params: params })
}

// 账户信息接口接口---
export const apiUserAccountInfo = params => {
  return axios.get('/dpswapi/v1/fc-account/info', { params: params })
}

// 关注/取消关注用户---
export const apiFocusUser = params => {
  return axios.post('/dpswapi/v1/user/focus', params)
}

// 关注/取消关注期货账户---
export const apiFocusAccount = params => {
  return axios.post('/dpswapi/v1/fc-account/focus', params)
}

// 获取关注期货账户列表---
export const apiFocusAccountList = params => {
  return axios.get('/dpswapi/v1/fc-account/focus-list', { params: params })
}

// 获取关注用户列表---
export const apiFocusUserList = params => {
  return axios.get('/dpswapi/v1/user/focus-list', { params: params })
}

// mom申请提交---
export const apiMomApply = params => {
  return axios.post('/dpswapi/v1/mom/apply', params)
}

// 用户消息---
export const apiMessage = params => {
  return axios.post('/dpswapi/v1/user/message-list', params)
}

// 网站注册协议
export const apiGvrp = params => {
  return axios.get('/dpswapi/v1/default/gvrp', { params: params })
}

// LevelUpTip ---delete
export const apiLevelUpTip = params => {
  return axios.get('/dpswapi/v1/default/level-up-tip', { params: params })
}
// ---delete
export const apiReadLevelTip = params => {
  return axios.post('/dpswapi/v1/default/read-level-tip', params)
}

// 帮助中心---delete
export const apiHelp = params => {
  return axios.get('/dpswapi/v1/help/index', { params: params })
}

// ---
export const apiCodeImage = params => {
  return axios.get('/dpswapi/v1/fc-account/code', { params: params })
}

// 获取账户统计数据
export const apiAccountData = params => {
  return axios.get('/dpswapi/v1/account/rank', { params: params })
}

// 获取账户图表统计数据---
export const apiUserAccountInfoData = params => {
  return axios.get('/dpswapi/v1/fc-account/chart-data', { params: params })
}

// 帮助中心---
export const apiGetCompany = params => {
  return axios.get('/dpswapi/v1/fc-contest/company', { params: params })
}

// 获取大赛统计数据---
export const apiGetStat = params => {
  return axios.get('/dpswapi/v1/fc-contest/stats', { params: params })
}

// 发送报名手机验证码
export const apiInvitationMCaptcha = params => {
  return axios.post('/dpswapi/v1/activity/mcaptcha', params)
}

// 发送报名申请
export const apiActivityApply = params => {
  return axios.post('/dpswapi/v1/activity/apply', params)
}

// 获取业绩鉴证排行榜数据---
export const apiGetPerformance = params => {
  return axios.get('/dpswapi/v1/fc-performance/index', { params: params })
}

// 获取业绩鉴证详情---
export const apiGetPerformanceDetail = params => {
  return axios.get('/dpswapi/v1/fc-performance/detail', { params: params })
}

// 会议二维码签到
export const apiActivityVerify = params => {
  return axios.post('/dpswapi/v1/activity/verify', params)
}

// 获取资金账号改保证金账号验证码
export const apiGTJACodeImage = params => {
  return axios.get('/dpswapi/v1/gtja/code-image', params)
}

// 资金账号改保证金账号提交
export const apiGTJAChange = params => {
  return axios.post('/dpswapi/v1/gtja/change', params)
}

// 获取用户通知---
export const apiUserNotify = params => {
  return axios.get('/dpswapi/v1/user/message', { params: params })
}

// 用户通知已读操作---
export const apiReadNotify = params => {
  return axios.post('/dpswapi/v1/user/read-message', params)
}

// 开户公司---
export const apiOpenCompany = params => {
  return axios.post('/dpswapi/v1/home/open-company', params)
}

// /article/flash
export const apiFlash = params => {
  return axios.get('/dpswapi/v1/article/flash', { params: params })
}
// 股票证券公司
export const apiGetStockCompany = params => {
  return axios.get('/dpswapi/v1/stock/company', { params: params })
}

// 股票发送验证码
export const apiStockSms = params => {
  return axios.post('/dpswapi/v1/stock/sms', params)
}

// 股票报名申请
export const apiStockApply = params => {
  return axios.post('/dpswapi/v1/stock/apply', params)
}

// 股票报名
export const apiStockSignUp = params => {
  return axios.post('/dpswapi/v1/stock/sign-up', params)
}
// 股票排名
export const apiGetStockRank = params => {
  return axios.get('/dpswapi/v1/sc-rank/index', { params: params })
}
// 股票月榜
export const apiGetStockRankMonth = params => {
  return axios.get('/dpswapi/v1/sc-rank/month', { params: params })
}
// 股票周榜
export const apiGetStockRankWeek = params => {
  return axios.get('/dpswapi/v1/sc-rank/week', { params: params })
}
// 股票账户列表
export const apiGetScAccount = params => {
  return axios.get('/dpswapi/v1/sc-account/index', { params: params })
}
// 股票账户详情
export const apiGetScAccountInfo = params => {
  return axios.get('/dpswapi/v1/sc-account/info', { params: params })
}
// 操作关注股票账户
export const apiFocusScAccount = params => {
  return axios.post('/dpswapi/v1/sc-account/focus', params)
}
// 关注的股票列表
export const apiFocusScAccountList = params => {
  return axios.get('/dpswapi/v1/sc-account/focus-list', { params: params })
}
// 修改股票账户
export const apiScAccountEdit = params => {
  return axios.post('/dpswapi/v1/sc-account/edit', params)
}
// 股票月盈亏
export const apiScMonthProfit = params => {
  return axios.get('/dpswapi/v1/sc-account/month-profit', { params: params })
}
// 持仓列表
export const apiScHoldList = params => {
  return axios.get('/dpswapi/v1/sc-account/hold-list', { params: params })
}
// 是否完成参赛
export const apiScComplate = params => {
  return axios.get('/dpswapi/v1/sc-account/complete-status', { params: params })
}
// 股票账户详情/sc-account/info
export const apiScAccountDetail = (params) => {
  return axios.get('/dpswapi/v1/sc-account/detail', { params: params })
}
// 删除股票账户
export const apiScAccountDel = (params) => {
  return axios.post('/dpswapi/v1/sc-account/delete', params)
}
// 股票数据上传
export const apiScUpload = params => {
  return axios.post('/dpswapi/v1/sc-account/upload-data', params)
}
// 获取需要上传数据的账户列表
export const apiGetScUploadAccount = (params) => {
  return axios.get('/dpswapi/v1/sc-account/upload-accounts', { params: params })
}
// 设置数据上传方式
export const apiSetScDataSource = (params) => {
  return axios.post('/dpswapi/v1/sc-account/set-data-source', params)
}
// 获取股票上传日期
export const apiScUploadDate = (params) => {
  return axios.get('/dpswapi/v1/sc-account/upload-date', { params: params })
}
// 获取股票上传数据详情
export const apiScUploadDateInfo = (params) => {
  return axios.get('/dpswapi/v1/sc-account/upload-data-info', { params: params })
}
// 获取股票上传历史列表
export const apiScUploadDateList = (params) => {
  return axios.get('/dpswapi/v1/sc-account/upload-data-list', { params: params })
}
// 获取股票列表
export const apiScList = (params) => {
  return axios.get('/dpswapi/v1/stock/list', { params: params })
}
// 获取网站协议
export const apiSiteInfo = (params) => {
  return axios.get('/dpswapi/v1/site-info/all', { params: params })
}
// 期货荣誉
export const apiFcHonor = (params) => {
  return axios.get('/dpswapi/v1/fc-account/honor', { params: params })
}
// 股票荣誉
export const apiScHonor = (params) => {
  return axios.get('/dpswapi/v1/sc-account/honor', { params: params })
}
// 股票买入榜
export const apiBuyRank = (params) => {
  return axios.get('/dpswapi/v1/stock/buy-rank', { params: params })
}
// 股票买入榜
export const apiSellRank = (params) => {
  return axios.get('/dpswapi/v1/stock/sell-rank', { params: params })
}
// 期货分组列表
export const apiGroupList = (params) => {
  return axios.get('/dpswapi/v1/fc-account-group/list', { params: params })
}
// 增加期货分组
export const apiGroupAdd = params => {
  return axios.post('/dpswapi/v1/fc-account-group/add', params)
}
// 修改分组名称
export const apiGroupEdit = params => {
  return axios.post('/dpswapi/v1/fc-account-group/edit', params)
}
// 删除分组
export const apiGroupDelete = params => {
  return axios.post('/dpswapi/v1/fc-account-group/delete', params)
}
// 排序
export const apiSort = params => {
  return axios.post('/dpswapi/v1/fc-account/sort', params)
}
// 期货账户总数
export const apiFcCount = (params) => {
  return axios.get('/dpswapi/v1/fc-account/count', { params: params })
}
// 挑战赛
// 我的suoy战绩
export const apiPlaylist = params => {
  return axios.get('/dpswapi/v1/fc-challenge/list', { params: params })
}
// 进行中
export const apiPlayUnderway = params => {
  return axios.get('/dpswapi/v1/fc-challenge/underway', { params: params })
}
// 已完成
export const apiPlayDone = params => {
  return axios.get('/dpswapi/v1/fc-challenge/done', { params: params })
}
// 最新1v1
export const apiPlayBattle = params => {
  return axios.get('/dpswapi/v1/fc-challenge/battle', { params: params })
}
// 最新擂台
export const apiPlayArena = params => {
  return axios.get('/dpswapi/v1/fc-challenge/arena', { params: params })
}
// 胜率榜
export const apiPlayVictory = params => {
  return axios.get('/dpswapi/v1/fc-challenge/win-rank', { params: params })
}
// 积分榜
export const apiPlayIntegral = params => {
  return axios.get('/dpswapi/v1/fc-challenge/integral-rank', { params: params })
}
// 发起挑战
export const apiPlayAdd = params => {
  return axios.post('/dpswapi/v1/fc-challenge/add', params)
}
// 详情
export const apiPlayInfo = params => {
  return axios.get('/dpswapi/v1/fc-challenge/info', { params: params })
}
// 用户挑战数据统计
export const apiPlayCount = params => {
  return axios.get('/dpswapi/v1/fc-challenge/count', { params: params })
}
// 我的vs
// 进行中
export const apiMyPlayUnderway = params => {
  return axios.get('/dpswapi/v1/fc-challenge/my-underway', params)
}
// 最新挑战
export const apiMyPlayNew = params => {
  return axios.get('/dpswapi/v1/fc-challenge/my-new', params)
}
// 已完成
export const apiMyPlayEnd = params => {
  return axios.get('/dpswapi/v1/fc-challenge/my-done', params)
}
// 我发起的
export const apiMyPlayInitiate = params => {
  return axios.get('/dpswapi/v1/fc-challenge/my-initiate', params)
}
// 应战
export const apiPlayAccept = data => {
  return axios.post('/dpswapi/v1/fc-challenge/accept', data)
}
// 撤销
export const apiPlayCancel = data => {
  return axios.post('/dpswapi/v1/fc-challenge/cancel', data)
}
// 拒绝
export const apiPlayRefuse = data => {
  return axios.post('/dpswapi/v1/fc-challenge/refuse', data)
}
// 周收益榜
export const apiFcWeek = params => {
  return axios.get('/dpswapi/v1/fc-rank/week', params)
}
// 所有可见账户
export const apiFcAll = params => {
  return axios.get('/dpswapi/v1/fc-account/all', params)
}
// 盘币输了/user/coin
export const apiCoin = params => {
  return axios.get('/dpswapi/v1/user/coin', params)
}
// 推荐账户/recommend/fc-accounts
export const apiRecommendAcct = params => {
  return axios.get('/dpswapi/v1/recommend/fc-accounts', { params: params })
}
// 推荐盘手
export const apiRecommendUser = params => {
  return axios.get('/dpswapi/v1/recommend/user-list', { params: params })
}
// 百科盘手列表
export const apiTrader = (params) => {
  return axios.get('/dpswapi/v1/recommend/users', { params: params })
}
// 编辑展示资料
export const apiEditProfile = data => {
  return axios.post('/dpswapi/v1/user/edit-profile', data)
}
// 上传视频
export const apiUploadVideo = data => {
  return axios.post('/dpswapi/v1/upload/video', data)
}
// 上传图片
export const apiUploadImg = data => {
  return axios.post('/dpswapi/v1/upload/image', data)
}
// 展示设置查询
export const apiDisplay = params => {
  return axios.get('/dpswapi/v1/user/profile', { params: params })
}
// 通过账户id查询用户信息
export const apiFcUser = (params) => {
  return axios.get('/dpswapi/v1/fc-account/get-user', { params: params })
}
// 盘手详情
export const apiProfileInfo = params => {
  return axios.get('/dpswapi/v1/user-profile/display', { params: params })
}
// 盘手展示账户列表/fc-account/show-list
export const apiShowList = params => {
  return axios.get('/dpswapi/v1/fc-account/show-list', { params: params })
}
// 盘手尽调
export const apiProfileInspect = params => {
  return axios.get('/dpswapi/v1/user-profile/inspect', { params: params })
}
// 盘手专访
export const apiProfileIntervier = params => {
  return axios.get('/dpswapi/v1/user-profile/interview', { params: params })
}
// 未读消息数量
export const apiUnreadMes = params => {
  return axios.get('/dpswapi/v1/user/unread-number', { params: params })
}
// 获取小程序链接
export const apiMiniLink = data => {
  return axios.post('/dpswapi/v1/share/mini-url-scheme', data)
}
// 期货产品列表
export const apiFcFund = params => {
  return axios.get('/dpswapi/v1/fc-fund/list', { params: params })
}
// 期货产品详情
export const apiFcFundInfo = params => {
  return axios.get('/dpswapi/v1/fc-fund/detail', { params: params })
}
// 期货产品收益图
export const apiFcFundProfit = params => {
  return axios.get('/dpswapi/v1/fc-fund/profit', { params: params })
}
// 期货产品回撤图数据
export const apiFcFundRetreat = params => {
  return axios.get('/dpswapi/v1/fc-fund/retreat', { params: params })
}
// 期货产品所属基金公司
export const apiFcFundCompany = params => {
  return axios.get('/dpswapi/v1/fc-fund/company', { params: params })
}
// 关注的产品列表
export const apiFcFundFocusList = params => {
  return axios.get('/dpswapi/v1/fc-fund/focus-list', { params: params })
}
// 关注、取消关注产品
export const apiFcFundFocus = data => {
  return axios.post('/dpswapi/v1/fc-fund/focus', data)
}
//  全部关注的产品
export const apiFcFundFocusIds = data => {
  return axios.post('/dpswapi/v1/fc-fund/focus-ids', data)
}
// 证书查询
export const apicertList = params => {
  return axios.get('/dpswapi/v1/fc-honor-cert/search', { params: params })
}
// 下载证书
export const apiDownloadCert = params => {
  return axios.get('/dpswapi/v1/fc-honor-cert/download', { params: params })
}
// 业绩对比
export const apiContrast = data => {
  return axios.post('/dpswapi/v1/fc-performance/contrast', data)
}

// 东证期货独立参赛页面验证码
export const apiCodeImageDz = params => {
  return axios.get('/dpswapi/v1/fc-account-dz/code', { params: params })
}

// 东证期货独立参赛页面参赛接口
export const apiAccountCreateDz = data => {
  return axios.post('/dpswapi/v1/fc-account-dz/create', data)
}

// 东证期货一键参赛
export const apiBatchCampaignDz = data => {
  return axios.post('/dpswapi/v1/fc-account-dz/batch-campaign', data)
}

// 东证轻量组接口
export const apiRankLightDz = params => {
  return axios.get('/dpswapi/v1/fc-dz-rank/light', { params: params })
}

// 东证重量组接口
export const apiRankWeightDz = params => {
  return axios.get('/dpswapi/v1/fc-dz-rank/weight', { params: params })
}

// 东证月榜接口
export const apiRankMonthDz = params => {
  return axios.get('/dpswapi/v1/fc-dz-rank/month', { params: params })
}

// 获取账户图表统计数据
export const apiAccountInfoDz = params => {
  return axios.get('/dpswapi/v1/fc-account-dz/info', { params: params })
}

// 获取账户图表统计数据
export const apiAccountInfoDataDz = params => {
  return axios.get('/dpswapi/v1/fc-account-dz/chart-data', { params: params })
}
