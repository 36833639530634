export const TOKEN_KEY = 'token'
export const INVITE_CODE_KEY = 'inviter'
export const INVITER_DZ_KEY = 'inviter_dz'
export const USER_ID = 'userId'
export const USER_MOBILE = 'userMobile'
export const USER_SCSTATUS = 'userScstatus'
export const USER_SCEMAIL = 'userScemail'
export const USER_URL = 'userUrl'

export const mutations = {
  SET_TOKEN (state, token) {
    state.token = token
    localStorage.setItem(TOKEN_KEY, token)
  },
  SET_INVITE_CODE (state, inviteCode) {
    state.inviteCode = inviteCode
    sessionStorage.setItem(INVITE_CODE_KEY, inviteCode)
  },
  SET_INVITER_DZ (state, inviter) {
    state.inviter_dz = inviter
    sessionStorage.setItem(INVITER_DZ_KEY, inviter)
  },
  SET_ISLOADING (state, isLoading) {
    state.isLoading = isLoading
  },
  SET_USER_ID (state, userId) {
    state.userId = userId
    localStorage.setItem(USER_ID, userId)
  },
  SET_USER_MOBILE (state, userMobile) {
    state.userMobile = userMobile
    localStorage.setItem(USER_MOBILE, userMobile)
  },
  SET_FOOTER_STATUS (state, status) {
    state.footerStatus = status
  },
  SET_USER_SCSTATUS (state, userScstatus) {
    state.userScstatus = userScstatus
    localStorage.setItem(USER_SCSTATUS, userScstatus)
  },
  SET_USER_SCEMAIL (state, userScemail) {
    state.userScemail = userScemail
    localStorage.setItem(USER_SCEMAIL, userScemail)
  },
  SET_USER_URL (state, userUrl) {
    state.userUrl = userUrl
    localStorage.setItem(USER_URL, userUrl)
  }
}
