<template>
  <van-overlay :show="show">
    <div class="level-tip" v-if="show">
      <div class="level-name">{{levelName}}</div>
      <div class="level-icon">
        <img class="level-image" :src="'/static/img/levels/'+level+'.png'" alt="">
      </div>
      <div class="message-box">
        <p>您的账户({{nickname}})</p>
        <p>累计净利润已超过{{integral}}</p>
      </div>
      <van-button class="read-btn" type="primary" @click="read">已 阅</van-button>
    </div>
  </van-overlay>
</template>

<script>
import { apiUserNotify, apiReadNotify } from '../utils/api'

export default {
  name: 'LevelUpTip',
  data () {
    return {
      level: '',
      levelName: '',
      nickname: '',
      integral: '',
      show: false
    }
  },
  methods: {
    read () {
      this.show = false
      apiReadNotify({ id: this.id }).then((response) => {
        if (response.data.success) {
          this.show = false
        }
      })
    }
  },
  created () {
    this.$store.state.userId && apiUserNotify({ type: 'level' }).then((response) => {
      if (response.data.success && response.data.message !== '') {
        this.id = response.data.data.id
        this.level = response.data.data.message_object.account.level
        this.levelName = response.data.data.message_object.account.level_name
        this.nickname = response.data.data.message_object.account.nickname
        this.integral = response.data.data.message_object.account.profit
        this.show = true
      }
    })
  }
}
</script>

<style lang="scss" scoped>
.level-tip {
  position: fixed;
  height: 329px;
  width: 307px;
  top: 50%;
  left: 50%;
  margin-top: -164px;
  margin-left: -153px;
  background-image: url("../assets/level_tip_bg.png");
  background-size: 100%;
  z-index: 2001;

  .level-name {
    font-size: 22px;
    color: #FB6E29;
    font-weight: bold;
    position: absolute;
    top: 115px;
    width: 100%;
    text-align: center;
  }

  .level-icon {
    position: absolute;
    top: 150px;
    width: 100%;
    text-align: center;

    .level-image {
      height: 40px;
    }
  }

  .message-box {
    position: absolute;
    top: 205px;
    width: 100%;
    text-align: center;
    font-size: 18px;
    color: #FFFFFF;
    font-weight: bold;

    p {
      margin: 0;
    }
  }

  .read-btn {
    height: 42px;
    width: 156px;
    background-color: #FFFFFF;
    border: 1px solid #F4B148;
    line-height: 42px;
    border-radius: 20px;
    position: absolute;
    top: 273px;
    left: 50%;
    margin-left: -78px;
    color: #F4B148;
    font-size: 21px;
  }
}
</style>
