<template>
  <div class="home">
    <!-- <div class="full">
      <div id="public-web-container" class="hidden">
        <p class="">正在打开...</p>
        <a id="public-web-jump-button" href="javascript:" onclick="openWeapp()">
          <span id="public-web-jump-button-loading" class="weui-primary-loading weui-primary-loading_transparent"><i class="weui-primary-loading__dot"></i></span>
          打开小程序
        </a>
      </div>
      <div id="desktop-web-container" class="hidden">
        <p class="">请在手机打开网页链接</p>
      </div>
    </div> -->
    <van-sticky>
      <logo-vue></logo-vue>
    </van-sticky>
    <template v-if="isLoaded">
      <div class="wrap bg-white">
        <van-swipe class="swipe" :autoplay="3000" indicator-color="white">
          <van-swipe-item v-for="(banner, index) in banners" :key="index" @click="onSwipeClick(banner)">
            <!-- :src="banner.image" -->
            <!-- <img src="../assets/banner.jpg" style="width: 100%;" alt=""> -->
            <van-image :src="banner.image" :data-url="banner.url">
              <template v-slot:loading>
                <van-loading type="spinner" size="20"/>
              </template>
            </van-image>
          </van-swipe-item>
        </van-swipe>
      </div>
      <!--<div class="wrap">
        <div class="campaign-count-box">
          <h3 class="title">实时报名人数</h3>
          <div class="campaign-count">
            <span class="item" v-for="(num, nindex) in campaignCountNums" :key="nindex">{{num}}</span>
            <span>名</span>
          </div>
        </div>
      </div>-->
      <!-- 通知栏 -->
      <!-- <div class="wrap" v-show="dynamicList.length > 0">
        <div class="dynamic-box">
          <i class="iconfont mhps-tongzhi"></i>
          <div class="dynamic-list">
            <ul ref="dynamicUl">
              <li v-for="(ditem, index) in dynamicList" :key="index">
                <router-link :to="{ path:'/account', query: { id: ditem.account_id } }">
                  <i class="iconfont mhps-bianpao"></i>
                  <span>{{ditem.message}}</span>
                </router-link>
              </li>
            </ul>
          </div>
        </div>
      </div> -->
      <!--<div v-if="false" class="king-style-box">
        <div class="king-title"><span>王者风范</span><span>累计收益千万以上</span></div>
        <van-swipe :autoplay="5000" :loop="true" :show-indicators="false" :duration="1500">
          <van-swipe-item v-for="(item, index) in this.kingStyleList" :key="index">
            <div class="item-box">
              <router-link :to="`/account?id=${itm.id}`" class="item" v-for="(itm, idx) in item" :key="idx">
                <img class="king-style" :src="require(`../assets/king-style/king_${itm.level}.png`)"/>
                <span>{{ itm.nickname }}</span>
                <span>最高净利润</span>
                <span>{{ (Number(itm.profit_total)/10000).toFixed(2) }}万</span>
              </router-link>
            </div>
          </van-swipe-item>
        </van-swipe>
      </div>-->
      <!-- 广告 -->
      <!-- <div class="wrap">
        <router-link to="/user/invite">
          <van-image :src="require('../assets/ad.png')" class="ad"/>
        </router-link>
      </div> -->

      <!-- 金刚区 -->
      <van-grid class="main-nav" icon-size="62" :border="false">
        <van-grid-item :icon="require('../assets/icon/ph_icon.png')" text="业绩展示" to="/performance" />
        <van-grid-item :icon="require('../assets/icon/trader_icon.png')" text="盘手百科" to="/trader" />
        <van-grid-item :icon="require('../assets/icon/futures_icon.png')" text="大赛排名" to="/rank" />
        <van-grid-item :icon="require('../assets/icon/rule_icon.png')" text="大赛规则" to="/rule" />
        <van-grid-item :icon="require('../assets/icon/contest_icon.png')" text="我要参赛" to="/user/account?type=futuresAcc" />
        <van-grid-item :icon="require('../assets/icon/stat_icon.png')" text="大赛统计" to="/statistics" />
<!--        <van-grid-item :icon="require('../assets/icon/cert_icon.png')" text="证书查询" to="/certificate"/>-->
        <van-grid-item :icon="require('../assets/icon/qwh_icon.png')" text="交易分析" @click="openqwh" />
        <van-grid-item :icon="require('../assets/icon/submatch_icon.png')" text="赛中赛" to="/submatch" />
      </van-grid>

      <!-- 推荐盘手 -->
      <div class="wrap bg-white mt-10 border-rd5">
        <div class="title-row">
          <div class="title-text"><span>推荐盘手</span></div>
          <div class="title-more">
            <router-link to="/trader">
              <span>查看更多</span>
              <i class="van-icon van-icon-arrow title-right-icon"></i>
            </router-link>
          </div>
        </div>
        <div class="user-box">
          <van-tabs v-model="userActive" swipeable line-height="0" line-width="0" :ellipsis="false">
            <van-tab v-for="item in recommend_users" :key="item['user_id']">
              <template #title>
                <div class="user-item">
                  <router-link :to="`/traderAccount?id=${item['user_id']}`">
                    <!-- <img v-if="item.cover" :src="item['cover']" @error="e => { e.target['src'] = '../assets/icon/user_avatar.png' }" width="150"
                      alt="头像">
                    <img v-else :src="require('../assets/icon/user_avatar.png')"  alt=""> -->
                    <van-image :src="item['cover'] ? item['cover'] : ''" fit="cover">
                      <template v-slot:error>
                        <img :src="require('../assets/icon/user_avatar.png')" class="img-error">
                      </template>
                    </van-image>
                  </router-link>
                  <h6>
                    <router-link :to="`/traderAccount?id=${item['user_id']}`">{{ item['username'] }}</router-link>
                  </h6>
                  <div class="intro">{{ item['intro'] || '暂无介绍' }}</div>
                  <p class="">主要策略：<span class="text-333">{{ strategyDict[item['strategy']]||'-' }}</span></p>
                  <p class="">管理规模：<span class="text-333">{{ item['scale']||'-' }}{{item['scale']?'万':''}}</span></p>
                  <p class="">代表账户：
                    <span class="">
                      <router-link :to="`/performanceAccount?id=${item['account_id']}`">{{ item['account_name'] }}</router-link>
                    </span>
                  </p>
                  <!-- <div class="btn"><a href="https://work.weixin.qq.com/kfid/kfcf992d9b8b9d42f42">我要投TA</a></div> -->
                </div>
              </template>
            </van-tab>
          </van-tabs>
          <!-- <router-link :to="{path: '/detail', name: 'Detail', query: {type: 'report', id: item.id}}" class="news-card"
                       v-for="item in reports" :key="item.id">
            <img :src="item.thumb" alt="大盘手网赛事战报">
            <div class="news-content">
              <p class="news-title">{{item.title}}</p>
              <p class="news-desc">{{item.intro}}</p>
              <p class="news-info"><i class="iconfont mhps-eye"></i>{{item.view}}<span
                class="news-date">{{item.created_at}}</span></p>
            </div>
          </router-link> -->
        </div>
      </div>
      <!-- 推荐账户 -->
      <div class="wrap bg-white mt-10 border-rd5">
        <div class="title-row">
          <div class="title-text"><span>推荐账户</span></div>
          <div class="title-more">
            <router-link to="/performance">
              <span>查看更多</span>
              <i class="van-icon van-icon-arrow title-right-icon"></i>
            </router-link>
          </div>
        </div>
        <div class="account-box">
          <van-tabs v-model="acctActive" swipeable line-height="0" line-width="0" :ellipsis="false">
            <van-tab v-for="item in recommend_fc_accounts" :key="item['user_id']">
              <template #title>
                <div class="account-item">
                  <router-link :to="`/performanceAccount?id=${item['account_id']}`">
                    <h6 class="truncate">
                      {{ item['account_name'] }}
                    </h6>
                    <p class="text-666">{{ freDic[item['frequency']] }}交易</p>
                    <div class="center">
                      <p class="text-red">{{ item['ret_total'] || '0' }}</p>
                      <p class="text-666">展示以来收益({{ item['establish_time'] }})年</p>
                    </div>
                    <div>
                      <p class="text-333">{{ item['worth_total'] || '-' }}</p>
                      <p class="text-666">累计净值</p>
                    </div>
                  </router-link>
                  <!-- <div class="btn"><a href="https://work.weixin.qq.com/kfid/kfcf992d9b8b9d42f42">我要投TA</a></div> -->
                </div>
              </template>
            </van-tab>
          </van-tabs>
        </div>
      </div>
      <!-- 期货排行榜 -->
      <div class="wrap bg-white mt-10 border-rd5 ranks-block">
        <div class="title-row">
          <div class="title-text">
            <span :class="activeRankTab!==3?'':'gray-text'" @click="changeRankTab('', {params: {page: 1,size: '10'}}, 0)">期货排行</span>
            <!-- <span :class="activeRankTab===3?'':'gray-text'" @click="changeRankTab('', {params: {page: 1,size: '10'}}, 3)">股票排行</span> -->
          </div>
          <div class="title-more">
            <router-link to="/rank">
              <span>查看更多</span>
              <i class="van-icon van-icon-arrow title-right-icon"></i>
            </router-link>
          </div>
        </div>
        <div v-show="activeRankTab!==3" class="rank-tabs">
          <div class="scroll-box">
            <div :class="activeRankTab===index?'active item':'item'" v-for="(item, index) in rankTabs" :key="index"
                 @click="changeRankTab(item.api, item.params, index)">
              <span class="name">{{item.name}}</span>
            </div>
          </div>
        </div>
        <div class="rank-box">
          <table>
            <thead>
            <tr>
              <th class="th-1">排名</th>
              <th class="th-2">昵称</th>
              <th v-show="activeRankTab===0 || activeRankTab===1" class="th-3">综合得分</th>
              <th v-show="activeRankTab===2">月净值</th>
              <th v-show="activeRankTab!==3">当日权益</th>
              <th v-show="activeRankTab<2">累计净利润</th>
              <th v-show="activeRankTab===2">月净利润</th>
              <!-- <th v-show="activeRankTab===3">总收益</th>
              <th v-show="activeRankTab===3">总盈亏</th>
              <th v-show="activeRankTab===3">总资产</th> -->
              <!-- <th v-show="activeRankTab===3">最大回撤</th> -->
            </tr>
            </thead>
            <tbody>
            <template v-for="(rank, rindex) in ranks" >
              <tr v-if="activeRankTab!==3" @click="go_rank" :key="rindex">
                <template v-if="rank.rank_num < 4">
                  <td v-if="(rank.rank_num === 0)" class="rank-num-top text-red">名人堂</td>
                  <td v-else-if="(rank.rank_num === 1)" class="rank-num-top top1"></td>
                  <td v-else-if="(rank.rank_num === 2)" class="rank-num-top top2"></td>
                  <td v-else class="rank-num-top top3"></td>
                  <td>{{rank.nickname}}</td>
                  <td v-show="activeRankTab===0 || activeRankTab===1">{{rank.score}}</td>
                  <td v-show="activeRankTab===2">{{rank.networth | numberSimplify}}</td>
                  <td>{{rank.equity | numberSimplify}}</td>
                  <td>{{rank.profit|numberSimplify}}</td>
                </template>
                <template v-else>
                  <td class="rank-num">{{rank.rank_num}}</td>
                  <td>{{rank.nickname}}</td>
                  <td v-show="activeRankTab===0 || activeRankTab===1">{{rank.score}}</td>
                  <td v-show="activeRankTab===2">{{rank.networth|numberSimplify}}</td>
                  <td>{{rank.equity|numberSimplify}}</td>
                  <td>{{rank.profit|numberSimplify}}</td>
                </template>
              </tr>
              <!-- <tr v-else  @click="go_Scrank" :key="rindex+1">
                <template v-if="rank.rank < 4">
                  <td v-if="(rank.rank === 1)" class="rank-num-top top1"></td>
                  <td v-else-if="(rank.rank === 2)" class="rank-num-top top2"></td>
                  <td v-else class="rank-num-top top3"></td>
                  <td>{{rank.nickname}}</td>
                  <td>{{(Number(rank.total_profit_ret)*100).toFixed(2)}}%</td>
                  <td>{{Number(rank.total_profit).toFixed(2)|numberSimplify}}</td>
                  <td>{{Number(rank.total_asset).toFixed(2)|numberSimplify}}</td>
                </template>
                <template v-else>
                  <td class="rank-num">{{rank.rank}}</td>
                  <td>{{rank.nickname}}</td>
                  <td>{{(Number(rank.total_profit_ret)*100).toFixed(2)}}%</td>
                  <td>{{Number(rank.total_profit).toFixed(2)|numberSimplify}}</td>
                  <td>{{Number(rank.total_asset).toFixed(2)|numberSimplify}}</td>
                </template>
              </tr> -->
            </template>
            </tbody>
          </table>
        </div>
      </div>
      <!-- <div class="wrap">
        <div class="title-row">
          <div class="title-text"><span>财经快讯</span></div>
          <div class="title-more">
            <router-link to="/news">
              <span>查看更多</span>
              <i class="van-icon van-icon-arrow title-right-icon"></i>
            </router-link>
          </div>
        </div>
        <div class="report-box">
          <div class="report-card"
                       v-for="item in news" :key="item.id">
            <div class="flash-content">
              <p class="news-date">{{item.publish_time.substring(5)}}</p>
              <p class="news-title van-ellipsis" v-html="item.content"></p>
            </div>
          </div>
        </div>
      </div> -->
      <div class="wrap bg-white mt-10 border-rd5">
        <div class="title-row">
          <div class="title-text"><span>视频中心</span></div>
          <div class="title-more">
            <router-link to="/videos">
              <span>查看更多</span>
              <i class="van-icon van-icon-arrow title-right-icon"></i>
            </router-link>
          </div>
        </div>
        <div class="videos-box">
          <div @click="showVideo(item.file_path)" class="news-card" v-for="item in videos" :key="item.id">
            <img :src="item.cover" alt="大盘手网独家视频">
            <div class="news-content">
              <p class="news-title-two">{{item.title}}</p>
              <p class="news-desc">专访嘉宾：{{item.author}}</p>
              <p class="news-info"><i class="iconfont mhps-eye"></i>{{item.view_count}}<span
                class="news-date">{{item.created_at}}</span></p>
            </div>
          </div>
        </div>
      </div>
      <div class="wrap bg-white mt-10 border-rd5">
        <div class="title-row">
          <div class="title-text"><span>赛事战报</span></div>
          <div class="title-more">
            <router-link to="/report?type=2">
              <span>查看更多</span>
              <i class="van-icon van-icon-arrow title-right-icon"></i>
            </router-link>
          </div>
        </div>
        <div class="news-box">
          <router-link :to="{path: '/detail', name: 'Detail', query: {type: 'report', id: item.id}}" class="news-card"
                       v-for="item in reports" :key="item.id">
            <img :src="item.thumb" alt="大盘手网赛事战报">
            <div class="news-content">
              <p class="news-title">{{item.title}}</p>
              <p class="news-desc">{{item.intro}}</p>
              <p class="news-info"><i class="iconfont mhps-eye"></i>{{item.view}}<span
                class="news-date">{{item.created_at}}</span></p>
            </div>
          </router-link>
        </div>
      </div>
      <!-- <div class="wrap">
        <div class="title-row">
          <div class="title-text"><span>高手专访</span></div>
          <div class="title-more">
            <router-link to="/report?type=3">
              <span>查看更多</span>
              <i class="van-icon van-icon-arrow title-right-icon"></i>
            </router-link>
          </div>
        </div>
        <div class="news-box">
          <router-link :to="{path: '/detail', name: 'Detail', query: {type: 'master', id: item.id}}" class="news-card"
                       v-for="item in master" :key="item.id">
            <img :src="item.thumb" alt="">
            <div class="news-content">
              <p class="news-title">{{item.title}}</p>
              <p class="news-desc">{{item.intro}}</p>
              <p class="news-info"><i class="iconfont mhps-eye"></i>{{item.view}}<span
                class="news-date">{{item.created_at}}</span></p>
            </div>
          </router-link>
        </div>
      </div> -->
      <!-- 我要参赛按钮 -->
      <!-- <van-button type="default" class="campaign-btn" @click="campaignBtnClick"></van-button> -->
    </template>
    <!-- <footer-nav activeName="index"></footer-nav> -->
    <div v-if="videoVisible" class="video-bg">
      <div class="video-body">
        <iframe
          id="iframeContain"
          name="iframeContain"
          seamless
          scrolling="yes"
          :src="iframeURL"
          width="100%"
          height="300px"
        >
        </iframe>
        <div class="video-btn" @click="hideVideo"></div>
      </div>
    </div>
    <!-- <notify-add-sc v-show="perfectFlag" title="继续完成股票大赛参赛步骤" @close="perfectFlag = false" /> -->
    <ContactModal :show="zxShow" @closeModal="zxShow=false"></ContactModal>
  </div>
</template>

<script>
import LogoVue from '../components/Logo.vue'
// import NotifyAddSc from '../components/NotifyAddSc.vue'
import ContactModal from '../components/ContactModal.vue'
import {
  apiIndexData,
  apiRankMonth,
  apiRankLight,
  apiRankWeight,
  apiGetStockRank,
  apiBannerData,
  apiMiniLink
} from '../utils/api'

export default {
  name: 'Home',
  components: {
    LogoVue,
    ContactModal
    // NotifyAddSc
  },
  data () {
    return {
      isLoaded: false,
      zxShow: false,
      banners: [
        { url: 'www.baidu.com' }
      ],
      updateDate: '',
      ranks: [],
      news: [],
      videos: [],
      reports: [],
      master: [],
      dynamicList: [],
      recommend_users: [],
      recommend_fc_accounts: [],
      userActive: 0,
      acctActive: 0,
      serviceShow: false,
      intervalId: null,
      campaignCount: 0,
      kingList: [],
      activeRankTab: 0,
      videoVisible: false,
      perfectFlag: false,
      iframeURL: '',
      freDic: {
        high: '高频',
        days: '日内',
        shortline: '短线',
        midline: '中线',
        kline: '长线'
      },
      strategyDict: {
        1: '高频',
        2: '日内',
        3: '短线',
        4: '中线',
        5: '长线',
        6: '综合'
      },
      rankTabs: [
        {
          name: '轻量组',
          api: 'apiRankIndex',
          params: {
            page: 1,
            size: '10'
          }
        },
        {
          name: '重量组',
          api: 'apiRankIndex',
          params: {
            page: 1,
            size: '10'
          }
        }
        // {
        //   name: '月榜',
        //   api: 'apiRankMonth',
        //   params: {
        //     page: 1,
        //     size: '10'
        //   }
        // }
      ]
    }
  },
  computed: {
    campaignCountNums: function () {
      const campaignCountArr = this.campaignCount.toString().split('')
      const diff = 5 - campaignCountArr.length
      if (diff > 0) {
        for (let i = 0; i < diff; i++) {
          campaignCountArr.unshift('0')
        }
      }
      return campaignCountArr
    },
    kingStyleList: function () {
      const arr = []
      const len = this.kingList.length
      for (let i = 0; i < len; i += 4) {
        arr.push(this.kingList.slice(i, i + 4))
      }
      return arr
    }
  },
  created () {
    const inviteCode = this.$route.query.code
    if (inviteCode) {
      this.$store.commit('SET_INVITE_CODE', inviteCode)
    }
    apiBannerData({ target: 'm_index' }).then(res => {
      this.banners = res.data.data.list
    })
    apiIndexData().then(response => {
      if (response.data.success) {
        const { data } = response.data
        // this.updateDate = response.data.updateDate

        this.ranks = data.fc_light_ranks
        this.news = data.news
        this.videos = data.videos
        this.reports = data.report
        this.master = data.master
        this.dynamicList = data.notify
        this.recommend_users = data.recommend_users
        this.recommend_fc_accounts = data.recommend_fc_accounts
        // this.campaignCount = response.data.campaignCount
        this.isLoaded = true
        // this.kingList = response.data.masterList

        // this.$nextTick(() => {
        //   setTimeout(() => {
        //     const dynamicObj = this.$refs.dynamicUl
        //     const width = dynamicObj.scrollWidth
        //     let left = 0
        //     this.intervalId = setInterval(() => {
        //       if (width + left <= 0) {
        //         left = 0
        //       } else {
        //         left -= 0.5
        //       }
        //       dynamicObj.style = `transform: translateX(${left}px)`
        //     }, 20)
        //   }, 100)
        // })
      } else {
        this.$toast(response.data.message)
      }
    })
    // this.initWebSocket()
  },
  destroyed () {
    clearInterval(this.intervalId)
    this.intervalId = null
  },
  mounted () {
    if (this.$store.state.userId && !this.$store.state.userScstatus) {
      this.perfectFlag = true
    }
  },
  methods: {
    go_rank () {
      this.$router.push('/rank')
    },
    go_Scrank () {
      this.$router.push('/sharesrank')
    },
    onSwipeClick (item) {
      // this.$router.push(event.target.parentNode.getAttribute('data-url'))
      if (item.url.includes('http')) {
        window.open(item.url, item.target)
      } else {
        this.$router.push(item.url)
      }
    },
    campaignBtnClick () {
      this.$router.push({
        path: '/user/account'
      })
    },
    changeRankTab (api, params, index) {
      this.activeRankTab = index
      this.ranks = []
      switch (index) {
        case 0: // 轻量
          apiRankLight(params).then((res) => {
            if (res.data.success) {
              this.ranks = res.data.data.list
            } else {
              this.$toast(res.data.message)
            }
          })
          break
        case 1: // 重量
          apiRankWeight(params).then((res) => {
            if (res.data.success) {
              this.ranks = res.data.data.list
            } else {
              this.$toast(res.data.message)
            }
          })
          break
        case 2: // 月榜
          apiRankMonth(params).then((res) => {
            if (res.data.success) {
              this.ranks = res.data.data.list
            } else {
              this.$toast(res.data.message)
            }
          })
          break
        case 3: // 股票
          apiGetStockRank(params).then((res) => {
            if (res.data.success) {
              this.ranks = res.data.data.list
            } else {
              this.$toast(res.data.message)
            }
          })
          break
        default:
          apiRankLight({
            page: 1,
            size: '10'
          }).then((res) => {
            if (res.data.success) {
              this.ranks = res.data.data.list
            } else {
              this.$toast(res.data.message)
            }
          })
      }
    },
    showVideo (url) {
      this.iframeURL = url
      this.videoVisible = true
    },
    hideVideo () {
      this.videoVisible = false
    },
    openqwh () {
      // location.href = 'weixin://dl/business/?t=xxxxxxx'
      apiMiniLink({ path: '/pages/index/index' }).then(res => {
        location.href = res.data.data.link
      })
    },
    async openWeapp (onBeforeJump) {
      var c = window.c
      const res = await c.callFunction({
        name: 'public',
        data: {
          action: 'getUrlScheme'
        }
      })
      if (onBeforeJump) {
        onBeforeJump()
      }
      location.href = res.result.openlink
    }
    // initWebSocket () {
    //   const _self = this
    //   if ('WebSocket' in window) {
    //     console.log('支持websoket')
    //     var ws = new WebSocket('ws://ws.dps/ws')
    //     ws.onopen = function () {
    //       console.log('ws连接状态：' + ws.readyState)
    //     }
    //     // 接听服务器发回的信息并处理展示
    //     ws.onmessage = function (e) {
    //       if (JSON.parse(e.data).message === 'success') {
    //         _self.news.unshift(JSON.parse(e.data).data)
    //       }
    //     }
    //     // 监听并处理error事件
    //     ws.onerror = function (error) {
    //       console.log(error)
    //     }
    //   } else {
    //     console.log('不支持')
    //   }
    // }
  }
}
</script>

<style lang="scss" scoped>
  .home {
    height: 100%;
    padding-bottom: 1.73333rem;
    .swipe {
      font-size: 0;
    }
    .banner-img {
     width: 100%;
     height: 140px;
    }
    .hidden {
      display: none;
    }
    .full {
      display: none;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background-color: #fff;
    }

    .king-style-box {
      margin-bottom: 14px;

      .king-title {
        text-align: start;
        margin: 30px 0 20px 13px;
        height: 18px;
        font-size: 18px;
        line-height: 18px;
        font-weight: 400;
        color: #000000;

        > span:nth-of-type(2) {
          margin-left: 10px;
          height: 12px;
          font-size: 12px;
          line-height: 12px;
          font-weight: 400;
          color: #999999;
        }
      }

      .item-box {
        padding: 0 13px;
        display: flex;
        justify-content: space-evenly;

        .item {
          display: flex;
          flex-direction: column;
          width: 80px;
          height: 110px;

          > span {
            display: inline-block;
            width: 80px;
            text-align: center;
            font-family: PingFang SC;
            font-weight: 500;
            color: #333333;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }

          .king-style {
            width: 80px;
            height: 55px;
            margin: 0;
            object-fit: fill;
            object-fit: contain;
            object-fit: scale-down;
          }

          > span:nth-of-type(1) {
            height: 16px;
            line-height: 16px;
            font-size: 14px;
            margin: 3px 0 5px;
          }

          > span:nth-of-type(2) {
            height: 14px;
            line-height: 14px;
            color: #666666;
            font-size: 12px;
          }

          > span:nth-of-type(3) {
            height: 16px;
            line-height: 16px;
            margin: 3px 0 0;
            font-size: 16px;
            color: #AA0000;
          }
        }
      }
    }

    // .btns {
    //   display: block;
    //   font-size: 0;

    //   .van-col {

    //     .van-button {
    //       border: 0;
    //       height: 40px;
    //       width: 145px;
    //       background: linear-gradient(0deg, #AA0000, #E50000);
    //       font-size: 16px;

    //       &.entry-btn {
    //         background-image: url("../assets/entry_btn.png");
    //         background-size: 100% 100%;
    //       }

    //       &.login-btn {
    //         background-image: url("../assets/login_btn.png");
    //         background-size: 100% 100%;
    //       }
    //     }
    //   }
    // }

    .dynamic-box {
      overflow: hidden;
      display: flex;
      align-items: center;

      .mhps-tongzhi {
        color: #AA0000;
      }

      ul, li {
        list-style: none;
      }

      .dynamic-list {
        flex: 1;
        font-size: 14px;
        white-space: nowrap;
        overflow: hidden;

        li {
          display: inline-block;

          a {
            color: #AA0000;
          }
        }
      }
    }

    .campaign-count-box {
      background-color: #AA0000;
      border-radius: 5px;
      padding: 7px;

      .title {
        font-size: 16px;
        color: #FFFFFF;
        margin: 0 0 5px 0;
      }

      .campaign-count {
        background-color: #970000;
        border-radius: 5px;
        padding: 7px 0;
        border: 1px solid rgba(255, 255, 255, 1);

        span {
          display: inline-block;
          color: #FFFFFF;
          font-size: 16px;
          margin: 0;

          &.item {
            width: 25px;
            background: rgba(255, 255, 255, 1);
            border-radius: 3px;
            font-size: 24px;
            color: #AA0000;
            font-weight: bold;
            margin-right: 7px;
          }
        }
      }
    }

    .title-row {
      display: flex;
      box-sizing: border-box;
      position: relative;
      margin-bottom: 15px;

      .title-text {
        flex: 1;
        color: #000000;
        font-size: 18px;
        text-align: left;
        height: 28px;
        line-height: 28px;
        span {
          margin-right: 30px;
        }
        .gray-text {
          color: #999;
        }
      }

      .title-more {
        font-size: 12px;
        color: #808080;
        text-align: right;
        position: relative;
        overflow: hidden;
        vertical-align: middle;
        word-wrap: break-word;
        height: 28px;

        a {
          display: flex;
          line-height: 28px;
          color: #999;
          align-items: center;
        }
      }
    }
    .bg-white {
      background-color: #fff;
    }
    .border-rd5 {
      border-radius: 5px;
    }
    .mt-10 {
      margin-top: 10px;
    }

    .main-nav {
      ::v-deep .van-grid-item__content {
        padding-top: 10px;
        padding-bottom: 10px;
      }
    }

    .user-box {
      ::v-deep .van-tabs__wrap {
        height: auto;
        .van-tabs__nav {
          justify-content: start;
        }
      }
      ::v-deep .van-tab{
        padding: 0;
        flex: 0 0 130px;
        &.van-tab--active {
          font-weight: normal;
          color: #666;
        }
      }
      ::v-deep .van-tabs__nav--complete {
        padding: 2px;
      }
      .user-item {
        width: 130px;
        // height: 250px;
        box-shadow: 0px 1px 2px rgba(0,0,0,0.1);
        padding: 0 6px 10px;
        margin-top: 30px;
        margin-right: 10px;
        box-sizing: border-box;
        ::v-deep .van-image {
          width: 88px;
          height: 88px;
          margin-top: -25px;
          img {
            width: 88px;
            height: 88px;
            border-radius: 50%;
            object-fit: cover;
          }
          .van-image__error {
            background-color: transparent;
            img {
              object-fit: contain;
            }
          }
        }
        a:active {
          outline: none;
          color: #000;
        }
        h6 {
          font-size: 14px;
          color: #333;
          font-weight: bold;
          text-align: center;
          margin: 6px 0;
          a {
            color: #333;
          }
        }
        .intro {
          height: 41px;
          margin: 0;
          font-size: 10px;
          line-height: 14px;
          color: #999;
          text-align: left;
          margin-bottom: 6px;
          overflow: hidden;
          text-overflow: ellipsis;
          width: 100%;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 3;
          word-break: break-all;
        }
        .btn {
          background-color: #e1322d;
          color: #fff;
          border-radius: 2px;
          width: 94px;
          height: 28px;
          line-height: 28px;
          margin: 15px auto 5px;
          a {
            height: 28px;
            color: #fff;
            line-height: 28px;
          }
        }
        p {
          margin: 0 0 1px 0;
          font-size: 10px;
          line-height: 14px;
          color: #999;
          text-align: left;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          span {color: #333;}
          a {color: #333}
        }
      }
    }
  .account-box{
    ::v-deep .van-tabs__wrap {
      height: auto;
    }
    ::v-deep .van-tab{
      padding: 0;
      &.van-tab--active {
        font-weight: normal;
        color: #666;
      }
    }
    ::v-deep .van-tabs__nav--complete {
      padding: 2px;
    }
    .account-item {
      width: 130px;
      border: 1px solid #EBEBEB;
      padding: 15px 5px;
      margin: 0px 10px 0 0;
      text-align: center;
      line-height: 30px;
      background: rgba(255,255,255,0.39);
      box-sizing: border-box;
      a {
          color: #333;
        }
      .truncate {
        margin: 0;
        font-size: 14px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
      p {
        font-size: 10px;
        line-height:14px;
        margin: 0;

        &.text-333 {
          color: #333;
          font-size: 14px;
          margin-bottom: 2px;
        }
      }

      h6 {
        font-size: 14px;
        color: #333;
        line-height: 20px;
        margin-bottom: 2px;
      }

      .center {
        margin: 15px;

        .text-red {
          color: #DE2B2B;
          font-size: 14px;
          line-height: 20px;
          margin-bottom: 2px;
        }
      }

      .btn {
        width: 90px;
        background-color: #e1322d;
        color: #fff;
        line-height: 28px;
        border-radius: 2px;
        margin: 15px auto 0;
        a {
          height: 28px;
          color: #fff;
          line-height: 28px;
        }
      }
    }
  }
    .ranks-block {

      .rank-notify {
        color: #aa0000;
        font-size: 12px;
        margin: 0;
        text-align: left;
      }
    }
    .rank-tabs {
      // width: 360px;

      .scroll-box {
        width: fit-content;
        display: flex;
        padding-top: 0;

        .item {
          margin-right: 30px;
          display: flex;
          flex-direction: column;
          align-items: center;
          color: #666;

          &.active {
            color: #aa0000;
            // border: 1px solid #aa0000;
          }

          .icon {
            width: 55px;
            height: 55px;
          }

          .name {
            height: 16px;
            font-size: 16px;
            line-height: 16px;
          }

          .explain {
            height: 12px;
            font-size: 12px;
            font-weight: 400;
            color: #999999;
            line-height: 12px;
          }
        }
      }
    }

    .rank-box {
      background: #ffffff;
      padding: 15px 0;
      // margin-top: 15px;
      border-radius: 5px;
      // min-height: 334px;

      table {
        width: 100%;
        table-layout: fixed;

        thead {
          th {
            font-size: 14px;
            font-weight: 500;
            color: #050505;
            line-height: 28px;

            &.th-1 {
              width: 30px;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }

            &.th-2 {
              width: 80px;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }

            &.th-3 {
              width: 60px;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }

            &.th-4 {
              width: 90px;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
          }
        }

        tbody {
          td {
            font-size: 14px;
            color: #999999;
            line-height: 28px;
            padding: 0;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            -o-text-overflow: ellipsis;
            -moz-text-overflow: ellipsis;
            -webkit-text-overflow: ellipsis;

            &.rank-num {
              color: #AA0000;
              font-weight: bold;
              font-family: Arial, serif;
              line-height: 28px;
              text-align: center;
            }

            &.rank-num-top {
              width: 18px;
              height: 22px;
              &.text-red {
                color: #aa0000;
              }

              &.top1 {
                background: url("../assets/rank-tabs/rank_top.png") no-repeat center;
                background-size: 18px 22px;
              }

              &.top2 {
                background: url("../assets/rank-tabs/rank_second.png") no-repeat center;
                background-size: 18px 22px;
              }

              &.top3 {
                background: url("../assets/rank-tabs/rank_third.png") no-repeat center;
                background-size: 18px 22px;
              }
            }
          }
        }
      }
    }

    .news-box,.videos-box {

      .news-card {
        text-align: left;
        padding: 10px;
        margin: 10px 0;
        display: flex;
        background: #ffffff;
        border-radius: 5px;
        box-shadow: 0px 0px 2px rgba(0,0,0,0.1);

        img {
          flex: none;
          width: 110px;
          height: 80px;
          border-radius: 5px;
        }

        .news-content {
          flex: 1;
          padding-left: 10px;
          overflow: hidden;

          .news-title {
            width: 100%;
            font-size: 14px;
            margin: 0;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            color: #000000;
          }

          .news-desc {
            width: 100%;
            overflow: hidden;
            text-overflow: ellipsis;
            -webkit-line-clamp: 2; // 2行
            word-break: break-all; // 换行
            display: -webkit-box;
            -webkit-box-orient: vertical;
            font-size: 14px;
            line-height: 20px;
            height: 40px;
            color: #999999;
            margin: 3px 0 0 0;
          }

          .news-title-two {
            width: 100%;
            font-size: 14px;
            margin: 0;
            overflow: hidden;
            text-overflow: ellipsis;
            color: #000000;
            -webkit-line-clamp: 2; // 2行
            word-break: break-all; // 换行
            display: -webkit-box;
            -webkit-box-orient: vertical;
          }

          .news-info {
            font-size: 12px;
            color: #999999;
            margin-top: 6px;
            margin-bottom: 0;

            i {
              font-size: 12px;
              margin-right: 3px;
            }

            .news-date {
              float: right;
              font-size: 12px;
            }
          }
        }
      }
    }
    .news-box .news-desc {
      min-height: 40px;
    }
    .report-box {
      background-color: #fff;
      padding: 15px 15px 15px 5px;
      .report-card {
        display: block;
        background-color: #fff;
        margin-bottom: 14px;
        &:last-child {
          margin-bottom: 0px;
        }
        .flash-content {
          line-height: 16px;
          p {
            font-size: 14px;
            color: #000;
            margin: 0;
            padding-right: 10px;
            text-align: left;
          }
          .news-date {
            color: #999;
            font-size: 12px;
            flex-shrink: 0;
            margin-bottom: 5px;
          }
        }
      }
    }

    .master-box {
      .master-card {
        display: block;
        font-size: 12px;
        text-align: left;
        background: #ffffff;

        img {
          width: 100%;
          height: 80px;
        }

        .master-content {
          padding: 5px;

          .master-title {
            margin: 0;
            font-weight: bold;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            color: #000000;
          }

          .master-desc {
            margin: 0;
            overflow: hidden;
            text-overflow: ellipsis;
            -webkit-line-clamp: 3; // 3行
            word-break: break-all; // 换行
            display: -webkit-box;
            -webkit-box-orient: vertical;
            color: #999999;
          }
        }
      }
    }

    .campaign-btn {
      position: fixed;
      bottom: 100px;
      right: 10px;
      height: 105px;
      width: 105px;
      background-image: url("../assets/campaign_btn.png");
      background-size: 100%;
      background-color: transparent;
      border: 0;
      border-radius: 50px;
    }
    .video-bg {
      position: fixed;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      z-index: 10;
      background-color: rgba(0,0,0,0.7);
      .video-body {
        margin-top: 50%;
      }
      .video-btn {
        width: 30px;
        height: 30px;
        background: url("../assets/close_btn.png") no-repeat;
        background-size: contain;
        margin: 20px auto 0;
      }
    }
  }
</style>
<style lang="scss">
  .notice {
    margin-top: 10px;
    height: 14px;
    line-height: 14px;
    font-size: 14px;
    text-align: left;
    color: #AA0000;
  }
</style>
