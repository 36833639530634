<template>
  <div class="zx-modal">
    <van-overlay :show="show" @click="close">
      <div class="wrapper" @click.stop>
        <div class="bg">
          <img src="../assets/qywxcode.png" alt="">
          <p>扫描二维码即可咨询盘手信息</p>
        </div>
        <div class="close-btn" @click="close"></div>
      </div>
    </van-overlay>
  </div>
</template>

<script>
import Vue from 'vue'
import { Overlay } from 'vant'

Vue.use(Overlay)
export default {
  name: 'HChart',
  props: {
    show: Boolean
  },
  data () {
    return {}
  },
  methods: {
    close () {
      this.$emit('closeModal')
    }
  }
}
</script>

<style scoped lang="scss">
.zx-modal {
  ::v-deep .van-overlay {
    z-index: 9;
  }
  .close-btn {
    width: 36px;
    height: 36px;
    background: url('../assets/close-icon-white.png') no-repeat;
    position: absolute;
    bottom: -80px;
    background-size: 36px;
    left: 50%;
    margin-left: -18px;
  }
}
.zx-modal .wrapper {
  width: 80%;
  margin: 34% auto 0;
  position: relative;
  .bg {
    background-color: #fff;
    border: 1px solid #707070;
    border-radius: 10px;
    padding: 20px 0 0 0;
  }
}
.zx-modal img {
  border: 1px solid #CCCCCC;
  width: 244px;
  border-radius: 10px;
  padding: 10px;
}
.zx-modal p {
  font-size: 16px;
  margin: 10px 0 15px;
  line-height: 24px;
  color: #333333;
  letter-spacing: 2px;
  text-align: center;
}
</style>
