import Vue from 'vue'
import Vuex from 'vuex'
import { INVITE_CODE_KEY, mutations, TOKEN_KEY, USER_ID, USER_SCSTATUS, USER_SCEMAIL, USER_MOBILE, USER_URL, INVITER_DZ_KEY } from './mutations'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    inviteCode: sessionStorage.getItem(INVITE_CODE_KEY) || '',
    token: localStorage.getItem(TOKEN_KEY) || '',
    isLoading: false,
    userId: localStorage.getItem(USER_ID) || '',
    userMobile: localStorage.getItem(USER_MOBILE) || '',
    userScstatus: localStorage.getItem(USER_SCSTATUS) || '',
    userScemail: localStorage.getItem(USER_SCEMAIL) || '',
    userUrl: localStorage.getItem(USER_URL) || '',
    footerStatus: true,
    inviter_dz: sessionStorage.getItem(INVITER_DZ_KEY) || ''
  },
  mutations,
  actions: {},
  modules: {},
  getters: {
    isLoggedIn: state => state.token !== ''
  }
})
