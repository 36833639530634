<template>
  <div class="header dps-header">
    <router-link to="/">
      <!-- <van-image width="101" :src="require('../assets/dpslogo.png')" class="logo"/> -->
      <img class="logo" :src="require('../assets/icon/dpslogo.png')"  width="80" alt="" />
    </router-link>
  </div>
</template>

<script>
export default {
  name: 'Logo',
  components: {
  },
  data () {
    return {
      active: 'home',
      show: false
    }
  },
  mounted () {
    this.active = this.activeName
  },
  methods: {
    onClick (name) {
      if (name === 'account') {
        this.show = true
        // location.href = 'https://hsapp.gtjaqh.com:8700/register_channel/index.html?channel=zghps'
      } else {
        this.show = false
        this.$router.push('/' + name)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.header.dps-header {
  background: #AA0000;
  color: #FFFFFF;
  height: 1.6rem;
  padding: 0;
  overflow: hidden;
  img {
    margin: 13px auto;
  }
  ::v-deep .router-link-exact-active {
    display: flex;
    margin: 0;
    padding: 0;
    justify-content: center;
  }
  ::v-deep .router-link-active {
    display: flex;
    margin: 0;
    padding: 0;
    justify-content: center;
  }
}
</style>
