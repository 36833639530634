import axios from 'axios'
import router from '../router'
import store from '../store/index'
import { Toast } from 'vant'

axios.defaults.baseURL = ''

const tip = (message, type = 'warning') => {
  if (type === 'success') {
    Toast.success(message)
  } else {
    Toast.fail(message)
  }
}

const goHome = () => {
  router.replace({
    path: '/'
  })
}

const toLogin = () => {
  router.replace({ path: '/login', query: { from: router.currentRoute.fullPath } })
}

// 创建axios实例
const instance = axios.create({
  timeout: 1000 * 12
})

// 所有请求加上token
instance.interceptors.request.use(
  config => {
    // 设置加载状态
    store.commit('SET_ISLOADING', true)
    const token = store.state.token
    token && (config.headers.Authorization = 'Bearer' + ' ' + token)
    return config
  },
  error => Promise.error(error)
)

// 响应拦截器
instance.interceptors.response.use(
  // 请求成功
  res => {
    store.commit('SET_ISLOADING', false)
    if (res.status === 200) {
      if (JSON.stringify(res.data.data) !== 'null' && ((res.data.data?.status && res.data.data.status === 401) || (res.data.data.status && res.data.data.status === 403))) {
        tip(res.data.message)
        toLogin()
      } else {
        return Promise.resolve(res)
      }
    }
    return Promise.reject(res)
  },
  // 请求失败
  error => {
    store.commit('SET_ISLOADING', false)
    const { response } = error
    if (response) {
      switch (response.status) {
        case 401:
          toLogin()
          break
        case 403:
          tip('您暂无权限访问！')
          // setTimeout(() => {
          //   goHome()
          // }, 1000)
          break
        case 404:
          tip('请求的资源不存在')
          setTimeout(() => {
            goHome()
          }, 1000)
          break
        default:
        // console.log(response.data.message)
      }
      return Promise.reject(response)
    } else {
      // 处理断网的情况
      tip('网络异常，请稍后重试！')
      return Promise.reject(error)
    }
  }
)

export default instance
