<template>
  <van-overlay class="open-account-company-box" :show="isShow">
    <div class="wrapper">
      <div class="block" @click.stop>
        <div class="title">请选择开户期货公司</div>
        <div class="company-box">
          <div class="item" @click="checkedIndex = 1">
            <div>
              <img class="logo" src="../assets/company-logo/guotai_logo.png">
              <span>国泰君安期货</span>
            </div>
            <img class="radio" v-show="checkedIndex === 1" src="../assets/company-logo/checked_icon.png">
          </div>
          <div class="item" @click="checkedIndex = 2">
            <div>
              <img class="logo" src="../assets/company-logo/guangfa_logo.png">
              <span>广发期货</span>
            </div>
            <img class="radio" v-show="checkedIndex === 2" src="../assets/company-logo/checked_icon.png">
          </div>
          <div class="item" @click="checkedIndex = 3">
            <div>
              <img class="logo" src="../assets/company-logo/guolian_logo.png">
              <span>国联期货</span>
            </div>
            <img class="radio" v-show="checkedIndex === 3" src="../assets/company-logo/checked_icon.png">
          </div>
          <div class="item" @click="checkedIndex = 4">
            <div>
              <img class="logo" src="../assets/company-logo/huachuang_logo.png">
              <span>华创期货</span>
            </div>
            <img class="radio" v-show="checkedIndex === 4" src="../assets/company-logo/checked_icon.png">
          </div>
          <div class="item" @click="checkedIndex = 5">
            <div>
              <img class="logo" src="../assets/company-logo/zhongxinjiantou_logo.png">
              <span>中信建投期货</span>
            </div>
            <img class="radio" v-show="checkedIndex === 5" src="../assets/company-logo/checked_icon.png">
          </div>
          <div class="item" @click="checkedIndex = 6">
            <div>
              <img class="logo" src="../assets/company-logo/xingzheng_logo.jpg">
              <span>兴证期货</span>
            </div>
            <img class="radio" v-show="checkedIndex === 6" src="../assets/company-logo/checked_icon.png">
          </div>
          <div class="item" @click="checkedIndex = 7">
            <div>
              <img class="logo" src="../assets/company-logo/guangda_logo.png">
              <span>光大期货</span>
            </div>
            <img class="radio" v-show="checkedIndex === 7" src="../assets/company-logo/checked_icon.png">
          </div>
        </div>
        <van-button type="default" class="comfirm-btn" @click="handleComfirm">确定</van-button>
      </div>
      <img class="cancel-btn" src="../assets/company-logo/cancel_btn.png" @click="handleCancel">
    </div>
  </van-overlay>
</template>
<script>
import { apiOpenCompany } from '../utils/api'
export default {
  name: 'OpenCompany',
  props: {
    isShow: {
      type: Boolean,
      default: false
    },
    needBack: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      checkedIndex: 1,
      openCompanyList: []
    }
  },
  created () {
    apiOpenCompany().then((response) => {
      if (response.data.success) {
        this.openCompanyList = response.data.data || []
      }
    })
  },
  methods: {
    handleComfirm () {
      switch (this.checkedIndex) {
        case 1:
          location.href = 'https://hsapp.gtjaqh.com:8700/ths_register_download/index.html?channel=zghps&depname=%E5%9B%BD%E6%B3%B0%E5%90%9B%E5%AE%89%E6%9C%9F%E8%B4%A7'
          break
        case 2:
          location.href = 'https://www.gfqh.cn/menu?id=146&name=%E4%B8%9A%E5%8A%A1%E6%B5%81%E7%A8%8B'
          break
        case 3:
          location.href = 'https://www.glqh.com'
          break
        case 4:
          location.href = 'http://www.hcqh.net/service.aspx?CateId=227'
          break
        case 5:
          location.href = 'https://app.cfc108.com:7443/m/other/views/friendInvitationToDownload.html?user_id=102000000001'
          break
        case 6:
          location.href = 'https://mp.weixin.qq.com/s/rObpgMsIp7zBntXXGsQ0wg'
          break
        case 7:
          location.href = 'http://www.qixili168.com/cms/?qudao=4'
          break
        default:
          location.href = 'http://m.dpswang.com'
      }
    },
    handleCancel () {
      if (this.needBack) {
        this.goBack()
      } else {
        this.$emit('handleClose')
      }
    }
  }
}
</script>
<style lang="scss">
  .open-account-company-box {
    .wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      height: 100%;

      .cancel-btn {
        margin: 10px auto;
        width: 27px;
        height: 27px;
      }

      .block {
        padding: 0 0 20px;
        width: 250px;
        height: 380px;
        background: url("../assets/company-logo/company_bg.png") no-repeat;
        background-size: 250px 380px;
        display: flex;
        flex-direction: column;
        align-items: center;

        .title {
          height: 20px;
          font-size: 18px;
          line-height: 20px;
          font-family: PingFang SC;
          color: #FFFEFE;
          margin: 10px auto 20px;
        }

        .company-box {
          height: fit-content;
          width: 100%;
          margin: 0 auto 20px;
          .item {
            position: relative;
            height: 38px;
            align-items: center;
            display: flex;
            padding: 0 20px;
            justify-content: space-between;

            &:after {
              content: '';
              position: absolute;
              display: block;
              bottom: 0;
              left: 0;
              width: 100%;
              height: 1px;
              background: #F2F2F2;
            }

            > div {
              display: flex;
              align-items: center;
              .logo {
                width: 15px;
                height: 15px;
              }

              span {
                margin-left: 10px;
                height: 12px;
                font-size: 12px;
                line-height: 12px;
                font-family: Source Han Sans CN;
                color: #262626;
              }
            }

            .radio {
              width: 15px;
              height: 15px;
            }
          }
        }

        .comfirm-btn {
          width: 210px;
          height: 30px;
          background: #AA0000;
          border-radius: 15px;
          font-size: 15px;
          font-family: Source Han Sans CN;
          color: #FFFFFF;
        }
      }
    }
  }
</style>
