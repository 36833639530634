<template>
  <div class="footer-page" >
    <!-- <van-nav-bar title="大盘手网" :border="false" class="header" @click-right="go_user">
      <i class="iconfont mhps-nicheng" slot="right"></i>
    </van-nav-bar> -->
    <!-- <van-tabs class="tabs" color="#AA0000" v-model="active" :border="false" title-active-color="#AA0000"
              @click="onClick">
      <van-tab title="首页" name=""></van-tab>
      <van-tab title="新闻中心" name="report"></van-tab>
      <van-tab title="大赛规则" name="rule"></van-tab>
      <van-tab title="奖项设置" name="award"></van-tab>
      <van-tab title="大赛排名" name="rank"></van-tab>
      <van-tab title="业绩鉴证" name="performance"></van-tab>
      <van-tab title="往届排名" name="history"></van-tab>
      <van-tab title="大赛统计" name="statistics"></van-tab>
      <van-tab title="荣誉殿堂" name="honor"></van-tab>
      <van-tab title="30亿MOM" name="mom"></van-tab>
      <van-tab title="极速开户" name="account"></van-tab>
    </van-tabs> -->
    <van-tabbar v-model="active">
      <van-tabbar-item replace to="/" name="Home"><i class="img home"></i>首页</van-tabbar-item>
      <!-- <van-tabbar-item replace to="/rule" name="futures"><i class="img futures"></i>期货大赛</van-tabbar-item> -->
      <!-- <van-tabbar-item replace to="/sharesrule" name="shares"><i class="img shares"></i>股票大赛</van-tabbar-item> -->
      <van-tabbar-item replace to="/message" name="Message" :badge="unNumber">
        <i class="img message"></i>消息
      </van-tabbar-item>
      <van-tabbar-item replace to="/user" name="user"><i class="img user"></i>我的</van-tabbar-item>
    </van-tabbar>
    <OpenCompany :is-show="show" @handleClose="show = false"/>
  </div>
</template>

<script>
import OpenCompany from './OpenCompany'
import { apiUnreadMes } from '../utils/api'
export default {
  name: 'HeaderNav',
  components: {
    OpenCompany
  },
  props: {
    activeName: String
  },
  data () {
    return {
      active: 'newHome',
      show: false,
      unNumber: ''
    }
  },
  mounted () {
    this.active = this.activeName
  },
  created () {
    this.$store.state.userId && apiUnreadMes().then((response) => {
      if (response.data.success && response.data.message !== '') {
        this.unNumber = response.data.data !== 0 ? response.data.data : ''
      }
    })
  },
  methods: {
  }
}
</script>

<style lang="scss">
.footer-page {
  .van-tabbar-item {
    .img {
      display: block;
      width: 20px;
      height: 20px;
      background-image: url("../assets/icon/home-icon.png");
      background-repeat: no-repeat;
      background-size: contain;
      margin: 0 auto 5px;
    }
    .img.futures {
      background-image: url("../assets/icon/futures-icon.png");
    }
    .img.shares {
      background-image: url("../assets/icon/shares-icon.png");
    }
    .img.user {
      background-image: url("../assets/icon/user_ft_icon.png");
    }
    .img.message {
      background-image: url("../assets/icon/message_icon.png");
    }
  }
  .van-tabbar-item.van-tabbar-item--active {
    .van-tabbar-item__text {
      color: #aa0000;
    }
    .img.home {
      background-image: url("../assets/icon/home-active_icon.png");
    }
    .img.futures {
      background-image: url("../assets/icon/futures-active_icon.png");
    }
    .img.shares {
      background-image: url("../assets/icon/shares-active_icon.png");
    }
    .img.message {
      background-image: url("../assets/icon/message_active_icon.png");
    }
    .img.user {
      background-image: url("../assets/icon/user-active_ft_icon.png");
    }
  }
  .van-info{
    right: -15px;
  }
}
// .header {
//   background: #AA0000;
//   color: #FFFFFF;
//   height: auto;
//   .van-nav-bar__content {
//     height: 60px;
//   }
//   .van-nav-bar__title {
//     color: #ffffff;
//     font-size: 18px;
//     height: 60px;
//     line-height: 60px;
//   }

//   .van-nav-bar__right {
//     line-height: 60px;
//     height: 60px;

//     .iconfont {
//       font-size: 18px;
//     }
//   }
// }
</style>
