<template>
  <van-overlay :show="show">
    <div class="modal-main" v-if="show">
      <div class="message-box">
        <p>{{messContent}}</p>
      </div>
      <van-button class="read-btn"  @click="read(true)">{{url?'查看详情':'去查看'}}</van-button>
      <span class="close-btn" @click="read(false)"></span>
    </div>
  </van-overlay>
</template>

<script>
import { apiUserNotify, apiReadNotify } from '../utils/api'

export default {
  name: 'ChallengeTip',
  data () {
    return {
      messContent: '',
      show: false,
      url: ''
    }
  },
  methods: {
    read (type) {
      this.show = false
      apiReadNotify({ id: this.id }).then((response) => {
        if (type) {
          if (this.url) {
            if (this.url.includes('http')) {
              window.open(this.url, '_blank')
            } else {
              this.$router.push({ path: this.url })
            }
          } else {
            this.$router.push({ path: '/play/myChallenge' })
          }
        }
      })
    }
  },
  created () {
    this.$store.state.userId && apiUserNotify({ type: 'user_fc_challenge' }).then(response => {
      if (response.data.success && response.data?.data?.message !== '') {
        this.messContent = response.data?.data?.message
        this.url = response.data?.data?.message_object?.link?.m_url || ''
        this.id = response.data?.data?.id
        this.show = true
      }
    })
  }
}
</script>

<style lang="scss" scoped>
.modal-main {
  position: fixed;
  height: 456px;
  width: 295px;
  top: 46%;
  left: 50%;
  margin-top: -228px;
  margin-left: -148px;
  background-image: url("../assets/award_modal_bg.png");
  background-size: 100%;
  z-index: 2001;

  .message-box {
    position: absolute;
    top: 252px;
    width: 100%;
    text-align: center;
    font-size: 14px;
    line-height: 24px;
    color: #AD4423;
    font-weight: bold;

    p {
      margin: 0;
      padding: 0 15px;
    }
  }

  .read-btn {
    height: 50px;
    width: 255px;
    line-height: 50px;
    position: absolute;
    top: 377px;
    left: 50%;
    margin-left: -128px;
    color: #FFFFFF;
    font-size: 21px;
    background-image: url("../assets/awardBtn_bg.png");
    background-size: 100%;
    background-color: rgba(255,255,255,0);
    border: none;
  }
  .close-btn {
    height: 37px;
    width: 37px;
    line-height: 50px;
    position: absolute;
    top: 486px;
    left: 50%;
    margin-left: -19px;
    background-image: url("../assets/close_btn.png");
    background-size: 100%;
  }
}
</style>
